import React, { useState, useEffect, useCallback } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import StyledName from '../components/styled-name';
import Video from '../components/video';
import WidthLimit from '../components/width-limit';

import Colors from '../constants/colors';
import Sizes, { min } from '../constants/sizes';

const ContestantsPage = ({
	data: {
		contestants: { nodes: contestants },
	},
}) => (
	<Layout>
		<Helmet>
			<title>Contestants</title>
		</Helmet>

		<main>
			<IntroSection className="grayDotted">
				<WidthLimit>
					<IntroGrid>
						<div>
							<h2>
								<StyledName givenName="Meet" familyName="the contestants" />
							</h2>
							<p>
								This casting call went out to over 14,000 agents in the Vancouver area and was viewed over 45,000 times across several platforms.
								There was an avalanche of applicants.
								Nine licensed agents made the cut, but only one of them will be crowned Vancouver’s Next Top Agent.
							</p>
						</div>
						<Video shadowed id="R0vU-xmi76U" />
					</IntroGrid>
				</WidthLimit>
			</IntroSection>

			<ContestantsSection>
				<WidthLimit>
					<Cards>
						<CardsList contestants={contestants} />
					</Cards>
				</WidthLimit>
			</ContestantsSection>
		</main>
	</Layout>
);

export default ContestantsPage;

const CardsList = ({ contestants }) => {
	// Konami code
	const sequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
	const [cursor, setCursor] = useState(0);
	const [showHidden, setShowHidden] = useState(false);

	const handleKeypress = useCallback(event => {
		if (cursor >= sequence.length) {
			// Already finished
			return;
		}

		const newCursor = event.keyCode === sequence[cursor] ? cursor + 1 : 0;
		setCursor(newCursor);

		if (newCursor === sequence.length) {
			// Sequence complete
			setShowHidden(true);
			document.getElementById('Andrew').scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
			if (window.ga) {
				window.ga('send', 'event', {
					eventCategory: 'Easter egg',
					eventAction: 'reveal',
					eventLabel: 'Andrew',
				});
			}
		}
	}, [cursor]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeypress);
		return () => {
			window.removeEventListener('keydown', handleKeypress);
		};
	}, [handleKeypress]);

	return contestants.filter(c => showHidden || !c.frontmatter.hidden).map(c => {
		const fullName = [
			c.frontmatter.givenName,
			c.frontmatter.familyName,
		].join(' ');
		return (
			<Card key={fullName} id={c.frontmatter.givenName}>
				<ImageContainer>
					<Img
						className="image"
						fluid={{
							...c.frontmatter.photo.childImageSharp.fluid,
							sizes: `(min-width: 1000px) 300px, (min-width: ${Sizes.tablet}px) 30vw, (min-width: 600px) 300px, 50vw`,
						}}
						alt={`Photo of ${fullName}`}
					/>
				</ImageContainer>
				<header>
					<h3>
						<StyledName {...c.frontmatter} />
					</h3>
				</header>
				<div className="content" dangerouslySetInnerHTML={{ __html: c.html }} />
			</Card>
		);
	});
}

const Section = styled.section`
	padding: 4rem 0;
`;

const IntroSection = styled(Section)`
	color: white;

	& h2 {
		font-size: 1.2rem;

		${min.tablet`
			font-size: 2rem;
		`}
	}

	${min.desktop`
		p {
			max-width: 30em;
		}
	`}
`;

const IntroGrid = styled.div`
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: auto;

	${min.tablet`
		grid-template-columns: min-content auto;
	`}
`;

const ContestantsSection = styled(Section)`
	background-color: #101112;
	background-image: repeating-linear-gradient(to bottom, transparent 0 47px, rgba(255, 255, 255, 0.015) 47px 48px);
	padding-top: 0.1em; /* Margin of the cards takes care of it */
`;

const ImageContainer = styled.div`
	width: 300px;
	max-width: 50vw;
	margin-top: -3em;
	display: flex;
	margin: -3em auto 0;
	${min.tablet`
		grid-column: 1;
		grid-row: 1 / 3;
		margin-left: 0;
		margin-right: 0;
		max-width: 30vw;
	`}
`;

const Cards = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Card = styled.article`
	background: ${Colors.darkMagenta};
	background: linear-gradient(to bottom, ${Colors.darkMagenta}, black 200%);
	background:
		repeating-linear-gradient(100deg, transparent 0 90px, rgba(0, 0, 0, 0.03) 91px 190px, transparent 200px),
		repeating-linear-gradient(100deg, transparent 0 18px, rgba(0, 0, 0, 0.04) 19px 222px, transparent 223px),
		repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.05) 2px 81px, transparent 82px 335px),
		linear-gradient(to bottom, ${Colors.darkMagenta}, black 200%);
	color: white;
	border-radius: 5px;
	margin-top: 5em;
	padding: 0 1em;
	box-shadow: 0 5px 2px -2px rgba(0, 0, 0, 0.3);

	&:nth-child(3n+2) {
		background: ${Colors.darkCyan};
		background: linear-gradient(to bottom, ${Colors.darkCyan}, black 200%);
		background:
			repeating-linear-gradient(100deg, transparent 0 90px, rgba(0, 0, 0, 0.03) 91px 190px, transparent 200px),
			repeating-linear-gradient(100deg, transparent 0 18px, rgba(0, 0, 0, 0.04) 19px 222px, transparent 223px),
			repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.05) 2px 81px, transparent 82px 335px),
			linear-gradient(to bottom, ${Colors.darkCyan}, black 200%);
	}

	&:nth-child(3n+3) {
		background: ${Colors.lightRoyalBlue};
		background: linear-gradient(to bottom, ${Colors.lightRoyalBlue}, black 200%);
		background:
			repeating-linear-gradient(100deg, transparent 0 90px, rgba(0, 0, 0, 0.03) 91px 190px, transparent 200px),
			repeating-linear-gradient(100deg, transparent 0 18px, rgba(0, 0, 0, 0.04) 19px 222px, transparent 223px),
			repeating-linear-gradient(100deg, transparent 0 1px, rgba(0, 0, 0, 0.05) 2px 81px, transparent 82px 335px),
			linear-gradient(to bottom, ${Colors.lightRoyalBlue}, black 200%);
	}

	${min.tablet`
		display: grid;
		grid-template-columns: max-content minmax(25ch, 50ch);
		grid-template-rows: auto auto;
		grid-gap: 0 2rem;
		padding: 0 2rem;
		max-width: 100%; /* For IE11 */
	`}

	& .image {
		margin-top: auto;
		width: 100%;
	}

	& header {
		grid-row: 2;
		grid-column: 1 / 3;
		font-size: 0.8rem;
		margin-top: -1em;
		margin-left: -1em;
		text-align: center;
		${min.tablet`
			font-size: 1.2rem;
			margin: auto 0 auto;
			text-align: left;
		`}

		& h3 {
			margin: 0;
		}
	}

	& .content {
		${min.tablet`
			grid-row: 1;
			grid-column: 2;
			margin: auto 0;
		`}
	}

	${min.tablet`
		border-radius: 40% 40% 40% 5px / 5px;

		&:nth-child(even) {
			border-radius: 40% 40% 5px 40% / 5px;
			grid-template-columns: minmax(25ch, 50ch) max-content;

			& header {
				text-align: right;
			}

			& .content {
				grid-column: 1;
			}

			& ${ImageContainer} {
				grid-column: 2;
			}
		}
	`}
`;

export const query = graphql`
	query {
		contestants: allMarkdownRemark(
			filter: { fileAbsolutePath: { glob: "**/src/contestants/*.md" } }
			sort: { fields: [frontmatter___order, frontmatter___familyName, frontmatter___givenName] }
		) {
			nodes {
				frontmatter {
					givenName
					familyName
					photo {
						childImageSharp {
							fluid(maxWidth: 330, quality: 80) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					hidden
				}
				html
			}
		}
	}
`;
